import * as EventTypes from "shared/eventTypes";
import { TAKEOVER_CLASSNAME, INFO_MSG_CLASSNAME } from "shared/iframeClasses";
import "./style.css";

const THENA_WRAPPER_ID = "thena-container";
const IFRAME_ID_APP = "thena-iframe-element";

// eslint-disable-next-line no-undef
const MAIN_JS_PATH = process.env.MAIN_JS_PATH;
// const MAIN_JS_PATH = 'http://localhost:3001/dist';

class Thena {
  constructor(
    onloadFunc = function () {
      //
    },
    onMagiclinkSuccessFunc = function () {
      //
    }
  ) {
    this.debugMode = false;
    this.onloadFunc = onloadFunc;
    this.onMagiclinkSuccessFunc = onMagiclinkSuccessFunc;
    this.domainAllowed = true; // optimistically assume for now
    this.handleBootstrapDone();
  }

  // PUBLIC API
  init = (clientId, email, hashedEmail) => {
    this.clientId = clientId;
    this.setCookie("thena-clientId", clientId, 10);
    this.setCookie("thena-email", email, 10);
    this.setCookie("thena-hashed-email", hashedEmail, 10);
    this.initializeIframe();
    this.iframe.classList.add(TAKEOVER_CLASSNAME);
    this.mountIframe();
  };

  update = ({ thenaKey, email, hashedEmail }) => {
    this.setCookie("thena-clientId", thenaKey, 10);
    if (email) this.setCookie("thena-email", email, 10);

    if (hashedEmail) this.setCookie("thena-hashed-email", hashedEmail, 10);

    this.initializeIframe();
    this.iframe.classList.add(TAKEOVER_CLASSNAME);
    this.mountIframe();
  };

  setEmail = ({ email, hashedEmail }) => {
    this.deleteCookie("thena-email");
    this.deleteCookie("thena-hashed-email");
    if (email) this.setCookie("thena-email", email, 10);
    if (hashedEmail) this.setCookie("thena-hashed-email", hashedEmail, 10);

    this.email = email;
    window.email = email;

    const cancelled = document.dispatchEvent(
      new Event("setEmail", { cancelable: true })
    );
    // console.log("is cancelled", !cancelled);
  };

  setConversationId = (conversationId) => {
    this.conversationId = conversationId;
    this.deleteCookie("thena-conversation-id");
    if (conversationId) {
      this.setCookie("thena-conversation-id", conversationId, 10);
    }
  };

  logout = () => {
    this.deleteCookie("thena-email");
    this.deleteCookie("thena-hashed-email");
    this.deleteCookie("thena-clientId");
    // delete thena-div
    const thenaApp = window.parent.document.getElementById("thena-app");

    if (thenaApp) {
      thenaApp.remove();
    }
  };

  setCookie(name, value, days) {
    let expires = "";
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie =
      name + "=" + (value || "") + expires + "; path=/" + ";SameSite=Lax";
    // localStorage.setItem(name, value);
  }

  deleteCookie(name) {
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }

  // PRIVATE METHODS

  onCancelFlow = () => {
    this.iframe.classList.remove(TAKEOVER_CLASSNAME);
    this.iframe.classList.remove(INFO_MSG_CLASSNAME);
    this.onFailedFlow("User cancelled login");
  };

  onFlowFinish = () => {
    this.iframe.classList.remove(TAKEOVER_CLASSNAME);
    // this.getCurrentUser();
  };

  onChangeContainerClass = (classnames) => {
    this.iframe.className = classnames;
    this.iframe.contentWindow.postMessage(
      { type: EventTypes.CHANGE_CONTAINER_CLASS_DONE },
      "*"
    );
  };

  handleBootstrapDone = () => {
    const thenaApi = window.thena;
    thenaApi.setEmail = this.setEmail;
    thenaApi.init = this.init;
    thenaApi.update = this.update;
    thenaApi.logout = this.logout;
    thenaApi._c = window.thena._c;
    thenaApi.setConversationId = this.setConversationId;

    this.runPriorCalls();
    window.thena = thenaApi;
  };

  handleDomainNotAllowed = () => {
    this.domainAllowed = false;
  };

  guessIfUserIsLoggedIn = () => {
    return !!this.getCookie();
  };

  getCookie = () => {
    const startIndex = document.cookie.indexOf(`THENA_AUTH-${this.clientId}`);
    if (startIndex === -1) {
      return null;
    }
    const startSlice = startIndex + 22;
    const endIndex = document.cookie.slice(startIndex).indexOf(";");
    if (endIndex === -1) {
      return document.cookie.slice(startSlice);
    } else {
      return document.cookie.slice(startSlice, startIndex + endIndex);
    }
  };

  initializeIframe = () => {
    // Setting the conversation id from url params //
    const url = window.location.href;
    const params = new URLSearchParams(new URL(url).search);
    const thenaConvId = params.get("thena-conv-id");
    window.thena.setConversationId(thenaConvId);

    if (!document.getElementById(IFRAME_ID_APP)) {
      const iframe = document.createElement("iframe");
      iframe.onload = () => {
        const thenaDiv = document.createElement("div");
        thenaDiv.id = THENA_WRAPPER_ID;
        this.iframe.contentDocument.body.appendChild(thenaDiv);

        // Load React App after mounting thenaDiv
        const script = document.createElement("script");
        script.src = `${MAIN_JS_PATH}/main.js`;
        script.type = "text/javascript";

        this.iframe.contentDocument.head.appendChild(script);

        this.iframe.contentWindow.postMessage(
          {
            type: EventTypes.INIT_IFRAME,
            value: {
              clientId: this.clientId,
              probablyLoggedIn: this.guessIfUserIsLoggedIn(),
              topHost: window.location.host,
              jwt: this.getCookie(),
            },
          },
          "*"
        );
      };
      iframe.src = "about:blank";
      iframe.id = IFRAME_ID_APP;
      iframe.setAttribute("allowFullScreen", "");
      iframe.setAttribute("role", "dialog");
      iframe.style = `position: fixed;
         bottom: 0;
         right: 0;
         z-index: 99999;
         height: 0;
         width: 0;
         display: none;`;
      this.iframe = iframe;
    }
  };

  runPriorCalls = () => {
    const allowedCalls = ["update", "setEmail", "logout", "setConversationId"];
    const priorCalls =
      window.thena && window.thena._c && typeof window.thena._c === "object"
        ? window.thena._c
        : [];
    priorCalls.forEach((call) => {
      const method = call[0];
      const args = call[1];
      if (allowedCalls.includes(method)) {
        this[method].apply(this, args);
      }
    });
    this.onloadFunc.call(window.thena, window.thena);
  };

  mountIframe = () => {
    if (!document.getElementById(IFRAME_ID_APP)) {
      document.body.appendChild(this.iframe);
    }
  };

  startFlow = (flowType = "LOGIN") => {
    this.iframe.contentWindow.postMessage(
      { type: EventTypes.START_AUTH_FLOW, value: flowType },
      "*"
    );
  };
}

export default ((window) => {
  const onloadFunc =
    window.thena &&
    window.thena.onload &&
    typeof window.thena.onload === "function"
      ? window.thena.onload
      : function () {
          //
        };
  const onMagiclinkSuccessFunc =
    window.thena &&
    window.thena.onEmailVerify &&
    typeof window.thena.onEmailVerify === "function"
      ? window.thena.onEmailVerify
      : function () {
          //
        };
  new Thena(onloadFunc, onMagiclinkSuccessFunc);
})(window);
